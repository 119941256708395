@import "../../theme.css";

.tab-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.tab-bar button {
  background-color: transparent;
  color: var(--font-color-secondary);
  position: relative;
}

.tab-bar button:hover {
  color: var(--secondary-color);
}

.tab-bar .select {
  background-color: var(--secondary-color);
  color: var(--font-color);
}

.tab-bar .select:hover {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .tab-bar {
    flex-direction: row;
    position: relative;
    z-index: 0;
  }

  .tab-bar button {
    margin-right: 5.5%;
  }
}
