/* Contenedor de miniaturas: Scroll horizontal sin desbordamiento */
.gallery-thumbnails {
  display: flex;
  gap: 20px;
  overflow-x: auto; /* Permite el scroll horizontal */
  padding: 10px;
  max-width: 100%; /* Asegura que no exceda el ancho de la pantalla */
  height: 350px;
}

.thumbnail-img {
  width: 100px; /* Tamaño fijo para las miniaturas */
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease;
}

.thumbnail-img:hover {
  transform: scale(1.05);
}

/* Ocultar la barra de desplazamiento solo en navegadores con soporte */
.gallery-thumbnails::-webkit-scrollbar {
  display: none;
}

.btnStore,
.btnGit {
  background-color: var(--secondary-color); /* Color de fondo del botón */
  width: fit-content; /* Ajusta el ancho según el contenido */
  padding: 10px 15px; /* Ajusta el padding para más espacio en los lados */
  border-radius: 15px; /* Bordes redondeados */
  display: flex; /* Usa flexbox para alinear elementos */
  align-items: center; /* Centra verticalmente los elementos */
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  transition: background-color 300ms, color 300ms; /* Transiciones suaves */
  margin-top: 40px;
}

.btnStore:hover {
  background-color: var(
    --primary-color
  ); /* Cambia el color de fondo en hover */
}

.btnStore:hover .icon {
  color: var(--secondary-color); /* Color del icono al hacer hover */
}

.btnStore:hover a {
  color: var(--secondary-color); /* Color del texto al hacer hover */
}

.btnStore .icon,
.btnGit .icon {
  font-size: 20px; /* Tamaño del icono */
  margin-right: 5px; /* Espacio entre el icono y el texto */
  transition: color 300ms; /* Transición suave para el color del icono */
}

.btnStore a,
.btnGit a {
  text-decoration: none; /* Sin subrayado en el texto */
  font-weight: bold; /* Texto en negrita */
  color: var(--font-color); /* Color del texto */
  transition: color 300ms; /* Transición suave para el color del texto */
}

.btnGit {
  background-color: var(--button-github);
}

.btnGit:hover {
  background-color: violet;
}

.btnGit:hover .icon,
.btnGit:hover a {
  color: #5d0061;
}

.links {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

@media (max-width: 768px) {
  p {
    margin-bottom: 30px;
  }
}
