@import "./theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(
    120deg,
    var(--primary-color) 60%,
    var(--secondary-color) 100%
  );
  color: var(--font-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layout {
  width: 80%;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  height: 132px;
  margin-bottom: 35px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.header img {
  width: 100px;
  margin-left: 20px;
}

.header .logo h3 {
  display: block;
  flex-basis: 40px;
  margin-left: 10px;
  font-weight: 300;
  font-size: 28px;
  text-transform: uppercase;
}

.header nav {
  flex-grow: 1;
}

.header nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  list-style: none;
  gap: 30px;
}

.header li {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
}

.header li a {
  color: var(--font-color);
  text-decoration: none;
  transition: all 300ms;
}

.header .active,
.header a:hover {
  color: var(--secondary-color);
}

.theme-toggle {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--font-color);
}

.theme-toggle:hover {
  color: var(--secondary-color) !important;
}

.content {
  min-height: 330px;
}

.footer {
  min-height: 40px;
  line-height: 7px;
  text-align: center;
  font-size: 14px;
  margin-top: 35px;
  padding-top: 45px;
  padding-bottom: 45px;
  color: var(--font-color);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.footer .rrss {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer .rrss .icon {
  margin-left: 15px;
  color: var(--font-color);
  transition: all 300ms;
}

.footer .rrss .icon:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

.page .heading {
  font-size: 55px;
  font-weight: bold;
}

.page img {
  width: 20%;
  height: auto;
  margin: 10px;
}

.home p {
  color: gray;
}

input[type="text"],
textarea {
  padding: 15px;
  display: block;
  width: 250px;
  margin: 20px;
  margin-top: 25px;
}

input[type="submit"],
button {
  font-size: 15px;
  padding: 15px;
  border: 0px;
  margin-top: 15px;
  border-radius: 5px;
  color: white;
  background-color: var(--secondary-color);
  text-decoration: none;
  cursor: pointer;
  transition: all 300ms;
}

input[type="submit"]:hover,
button:hover {
  color: black !important; /* Color del texto al hacer hover */
}

.works {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos pasen a la siguiente fila */
}

.work-item {
  position: relative;
  width: calc(33.33% - 40px); /* Para que 3 elementos quepan en una fila */
  margin: 20px; /* Ajusta el margen para que el espacio entre ellos sea adecuado */
}

.work-item .mask {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 15px #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-item img {
  width: 100%;
  margin-left: 0;
  transition: all 500ms;
}

.work-item .mask:hover img {
  width: 120%;
}

.work-item .mask a {
  width: 100%;
}

.work-item .mask .info {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 100%;
  padding: 15px;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  text-align: center;
  border: 1px solid white;
}

.work-item .mask:hover .info {
  opacity: 1;
}

.work-item h2,
.work-item span {
  margin: 0;
  padding: 5px 0;
}

.work-item span {
  color: lightgray;
  text-transform: capitalize;
}

.work-item .stacks {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.work-item .stacks h3 {
  margin-right: 10px;
}

/*prueba menu*/
/* Estilos del botón hamburguesa */
.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;
}

.menu-toggle span {
  width: 100%;
  height: 4px;
  background-color: var(--font-color);
  transition: all 300ms;
}

/* Estilos cuando el menú está activo (hamburguesa animada) */
.menu-toggle.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.active span:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .layout {
    width: 90%; /* Ocupa más espacio horizontal en móviles */
  }

  .menu-toggle {
    display: flex;
    position: absolute;
    right: -5px; /* Coloca el menú hamburguesa en la esquina derecha */
    top: 50%; /* Alinea verticalmente con el logo */
    transform: translateY(-50%);
    font-size: 24px; /* Ajusta el tamaño del icono */
  }

  nav {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: var(--primary-color);
    display: none;
    flex-direction: column;
    padding: 20px;
    gap: 15px;
  }

  nav.active {
    display: flex;
    height: 800px;
    margin-right: -15px;
    z-index: 1;
  }

  .header {
    height: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center; /* Alinea verticalmente los elementos en la misma fila */
    justify-content: center;
    position: relative; /* Para posicionar elementos de manera absoluta dentro de él */
  }

  .header img {
    margin: 0 auto; /* Centra el logo */
    width: 100px; /* Ajusta el tamaño del logo si es necesario */
  }

  .header .logo h3 {
    margin-left: 0; /* Quita el margen izquierdo */
    font-size: 22px; /* Reduce el tamaño del texto del logo */
  }

  .header nav ul {
    flex-direction: column; /* Cambia la dirección a columna */
    gap: 15px; /* Reduce el espacio entre los ítems */
    height: 100%;
    justify-content: flex-start;
  }

  .header li {
    font-size: 20px; /* Reduce el tamaño de la fuente del menú */
    margin: 5px 0; /* Ajusta el margen para los ítems */
  }

  .content {
    min-height: auto; /* Ajusta la altura mínima del contenido */
    padding: 10px;
  }

  .footer {
    padding: 20px 10px; /* Ajusta el padding en móviles */
    font-size: 12px; /* Reduce el tamaño de la fuente */
  }

  .footer .rrss {
    font-size: 30px; /* Reduce el tamaño de los iconos de redes sociales */
  }

  .page .heading {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
  }

  .page img {
    width: 50%; /* Aumenta el tamaño de las imágenes en móviles */
    margin: 5px;
  }

  .works {
    flex-direction: column; /* Coloca los elementos uno debajo del otro */
  }

  .work-item {
    width: 100%; /* Ocupa todo el ancho en móviles */
    margin: 10px 0; /* Ajusta el margen entre los elementos */
  }

  .work-item .mask {
    height: 250px; /* Reduce la altura de las imágenes */
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .work-item img {
    width: 100%;
    margin-left: 0;
  }

  input[type="text"],
  textarea {
    width: 100%; /* Ajusta el ancho a 100% en móviles */
    margin: 10px 0;
  }

  input[type="submit"],
  button {
    width: 100%; /* Asegura que los botones ocupen el ancho completo */
  }

  .theme-toggle {
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 20px;
    top: 20px;
  }
}
