/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que el modal esté sobre otros elementos */
}

/* Contenedor de contenido en el modal */
.modal-content {
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img {
  padding-right: 10px;
}

/* Botón de cerrar en el modal */
button {
  position: fixed;
  top: 10px; /* Distancia desde el borde superior del modal */
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001; /* Asegura que el botón esté sobre el contenido */
  border-radius: 5px;
  transition: all 300ms;
}

button:hover {
  color: black;
}

/* Estilos para mover la flecha izquierda a la esquina superior izquierda */
.image-gallery-left-nav {
  position: fixed; /* Fijar en la pantalla, no dentro del contenedor de la galería */
  top: 50%; /* Centra verticalmente */
  left: 0; /* Ajusta la distancia desde el borde izquierdo de la pantalla */
  z-index: 1002; /* Asegura que las flechas estén sobre la galería */
  width: min-content;
}

/* Estilos para mover la flecha derecha a la esquina superior derecha */
.image-gallery-right-nav {
  position: fixed; /* Fijar en la pantalla, no dentro del contenedor de la galería */
  top: 50%; /* Centra verticalmente */
  right: 20px; /* Ajusta la distancia desde el borde derecho de la pantalla */
  z-index: 1002; /* Asegura que las flechas estén sobre la galería */
  width: min-content;
}

@media (max-width: 768px) {
  /* Ajusta el botón de cerrar en pantallas móviles */
  button {
    width: 20%;
  }

  /* Oculta las flechas de navegación en pantallas móviles */
  .image-gallery-left-nav {
    display: none;
  }

  .image-gallery-right-nav {
    display: none;
  }

  /* Ajusta el tamaño del contenedor del modal en pantallas móviles */
  .modal-content {
    max-width: 90%; /* Usa casi todo el ancho de la pantalla */
    max-height: 90%; /* Usa casi toda la altura de la pantalla */
    padding: 10px; /* Agrega espacio interno */
  }

  /* Ajusta el tamaño de las imágenes en la galería para pantallas móviles */
  .image-gallery-slide img {
    width: 100%; /* Asegura que las imágenes ocupen el ancho completo */
    height: auto; /* Mantiene la proporción */
    margin-bottom: 10px; /* Agrega margen entre las imágenes */
    border-radius: 8px; /* Bordes redondeados para darle un mejor estilo */
    margin-left: 0;
  }
}
