@import "../../theme.css";

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .resume {
  width: 80%;
}

.home h1 {
  font-family: monospace;
  letter-spacing: 10px;

  margin-bottom: 0px;
  font-size: 40px;
  text-transform: uppercase;
}

.home strong {
  color: var(--secondary-color);
}

.home h2 {
  color: #b0b0b0;
  font-size: 18px;
  line-height: 35px;
}

.home .title {
  font-weight: 400;
}

.home .title a {
  color: var(--font-color);
  text-decoration: none;
  font-weight: bold;
  transition: all 300ms;
  font-size: 16px;
  text-transform: uppercase;
}

.home .title a:hover,
input[type="submit"]:hover,
button:hover {
  color: var(--secondary-color);
}

.home .heading,
.home .heading {
  font-size: 40px;
  font-weight: 500;
  color: var(--font-color);
  margin-bottom: 0px;
}

.home .about {
  display: flex;
  align-items: center;
  color: #b0b0b0;
  text-decoration: none;
  font-size: 18px;
  transition: all 300ms;
}

.home .about:hover {
  color: var(--secondary-color);
}

.home .about .arrow {
  margin-left: 10px;
  font-size: 25px;

  position: relative;
  animation: moveRight 600ms infinite alternate;
}

@keyframes moveRight {
  0% {
    left: 0;
  }
  100% {
    left: 10px;
  }
}

@media (max-width: 768px) {
  .home {
    width: 100%;
  }

  .home .resume {
    width: 100%;
  }

  .home h2 {
    font-size: 20px;
  }
}
