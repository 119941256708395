:root {
  --primary-color: black;
  --secondary-color: #fa4529;
  --font-color: white;
  --font-color-secondary: #b3b3b3;
  --button-github: #ae00ff;
}

/* Light theme */
.light-theme {
  --primary-color: #ffffff; /* Blanco limpio para fondo */
  --secondary-color: #4a90e2; /* Azul elegante y profesional */
  --font-color: #1a1a1a; /* Negro suave para texto principal */
  --font-color-secondary: #4f4f4f; /* Gris oscuro para subtítulos */
  --button-github: #3d85c6; /* Azul vibrante para botones */
}
