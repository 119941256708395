@import "../../theme.css";

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

.loading-spinner {
  margin-top: 40px;
  width: 40px;
  height: 40px;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--secondary-color); /* Color del spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
