.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"],
textarea {
  width: 50%;
  border-radius: 10px;
}

textarea {
  height: 100px;
}

@media (max-width: 768px) {
  .contact {
    width: 100%;
  }

  input[type="text"],
  textarea {
    width: 90%;
    font-size: 18px;
    border-radius: 10px;
  }
}
