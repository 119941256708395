.page {
  text-transform: uppercase;
}
.title_exp {
  display: flex;
  justify-content: space-between;
}

p {
  text-align: justify;
  margin-top: -10px;
}

.education li {
  display: flex;
  justify-content: space-between;
}

.education li span {
  text-transform: uppercase;
}

.certificates a {
  font-weight: bold;
}

.certificates a:hover {
  cursor: pointer;
  color: var(--secondary-color);
}
